var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col"},[_c('el-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.$store.getters['topics/usersLoad']),expression:"$store.getters['topics/usersLoad']"}],scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"main_header_wrapper"},[_c('div',{staticClass:"part_one"},[_c('i',{staticClass:"fa fa-database data_icon"}),_c('p',{staticClass:"text_wrapping"},[_vm._v(" "+_vm._s(_vm.local.level1)+" ")])]),_c('div',{staticClass:"part_two"},[_c('l-button',{staticClass:"btn_add_strategy",on:{"click":function($event){return _vm.open_strategy_dialog(1, _vm.local)}}},[_c('el-icon',{staticClass:"el-icon-plus btn_icon"}),_c('span',{staticClass:"btn_text"},[_vm._v(_vm._s(_vm.localization("Add Version")))])],1)],1)]),_c('div',{staticClass:"hidden-md-only hidden-lg-only hidden-xl-only"},[_c('Info',{attrs:{"items":_vm.localForInfo}})],1)]},proxy:true}])},[_c('el-tree',{attrs:{"data":_vm.local.children,"default-expand-all":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('div',{staticClass:"custom-tree-node"},[_c('div',{staticClass:"lang_text"},[_vm._v(_vm._s(data.title))]),_c('div',{staticClass:"main_wrapping_for_strategy"},[(node.level == 2)?_c('div',{staticClass:"part_one_text"},[_c('i',{staticClass:"fa fa-database text_icon_2"}),_c('p',{staticClass:"text_words_2"},[_vm._v(_vm._s(data.level1))])]):_vm._e(),(node.level == 2)?_c('div',{staticClass:"part_two_text"},[_c('l-button',{staticClass:"active_button",attrs:{"outline":"","round":"","size":"sm","type":data.removed == 0 ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(data.removed == 0 ? ("" + (_vm.localization("Info"))) : ("" + (_vm.localization("Inactive"))))+" ")]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(("" + (_vm.localization('Edit strategy')))),expression:"`${localization('Edit strategy')}`",modifiers:{"top-center":true}}],staticClass:"edit_strategy",on:{"click":function($event){return _vm.open_strategy_dialog(2, data)}}},[_c('i',{staticClass:"fa fa-edit"})]),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(
                data.removed == 0
                  ? ("" + (_vm.localization('Delete strategy')))
                  : ("" + (_vm.localization('Active strategy')))
              ),expression:"\n                data.removed == 0\n                  ? `${localization('Delete strategy')}`\n                  : `${localization('Active strategy')}`\n              ",modifiers:{"top-center":true}}],staticClass:"edit_strategy delete",on:{"click":function($event){return _vm.remove(data.id, data.removed)}}},[(data.removed == 0)?_c('i',{staticClass:"fa fa-close "}):_c('i',{staticClass:"fa fa-check text-success"})])],1):_vm._e()])])}}])})],1),_c('el-dialog',{staticClass:"add_strategy_dialog_user",attrs:{"title":_vm.type == 1
        ? ("" + (_vm.localization('Add Version')))
        : ("" + (_vm.localization('Edit Version'))),"width":"50%","visible":_vm.strategy_Dialog,"top":"4vh"},on:{"update:visible":function($event){_vm.strategy_Dialog=$event}}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.strategy,"rules":_vm.rules,"label-position":"top"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('el-form-item',{attrs:{"label":("" + (_vm.localization('Text'))),"label-width":"100px","prop":"level1"}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.strategy.level1),callback:function ($$v) {_vm.$set(_vm.strategy, "level1", $$v)},expression:"strategy.level1"}})],1),_c('el-form-item',{attrs:{"label":("" + (_vm.localization('Language'))),"label-width":"100px","prop":"language"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":("" + (_vm.localization('Please select language')))},model:{value:(_vm.strategy.language),callback:function ($$v) {_vm.$set(_vm.strategy, "language", $$v)},expression:"strategy.language"}},_vm._l((_vm.languages),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.title,"value":item.id}})}),1)],1)],1),_c('span',{staticClass:"\n        dialog-footer\n        user_profile_dialog_footer\n\n      ",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"text-center text-capitalize",on:{"click":function($event){_vm.strategy_Dialog = false}}},[_vm._v(_vm._s(_vm.localization("Cancel")))]),_c('el-button',{staticClass:"\n          text-center\n          text-capitalize\n        ",attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.localization("Save")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }